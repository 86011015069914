import * as game from "../game";

export function Grid({ history, word, gameEvaluations, complete, guess, allLettersUsed, caret, setCaret }) {
  return (
    <table className="table-fixed">
      <tbody>
        {history.map((record, recordIndex) => (
          <>
            <tr>
              {[...record.padEnd(9, "\u200b")].map(letter => (
                <td className={`border border-slate-600 w-8 xxs:w-10 text-xl xxs:text-3xl text-center uppercase ${record === word ? "bg-green-300" : ""}`}>
                  {letter}
                </td>
              ))}
            </tr>
            <tr>
              {game.scoreGuess(word, record).map((score, scoreIndex) => (
                <td className="border border-b-2 border-slate-600 w-8 xxs:w-10 text-xl xxs:text-3xl text-center text-slate-500 align-top">
                  {score.marks.map((scoreChar, scoreCharIndex) => (
                    <div
                      className={`${(complete || gameEvaluations[recordIndex][scoreIndex]?.evaluation[scoreCharIndex] === undefined)
                        ? ""
                        : gameEvaluations[recordIndex][scoreIndex]?.evaluation[scoreCharIndex]
                          ? ""
                          : "bg-yellow-200"}`}
                    >
                      {scoreChar}
                    </div>
                  ))}
                </td>
              ))}
              {Array.from({ length: 9 - record.length }, () => (
                <td className="border border-b-2 border-slate-600 w-8 xxs:w-10 text-xl xxs:text-3xl text-center text-slate-500">
                  <div>
                    {"\u200b"}
                  </div>
                </td>
              ))}
            </tr>
          </>
        ))}
        {!complete && (<tr>
          {[...guess].map((letter, i) => (
            <td
              className={`border border-slate-600 w-8 xxs:w-10 text-xl xxs:text-3xl text-center uppercase ${allLettersUsed.has(letter) &&
                (
                  Array.from(guess).filter(l => l === letter).length >
                  Array.from(word).filter(l => l === letter).length
                ) ? "bg-red-100" : ""} ${caret === i ? "!bg-blue-200" : ""}
                    `}
              onClick={() => setCaret(i)}
            >
              {letter}
            </td>
          ))}
        </tr>)}
      </tbody>
    </table>
  );
}
