import { KeyboardButton } from "./KeyboardButton";

export function Keyboard({ caretLeft, updateGuess, caretRight, allLettersUsed, word, enterGuess }) {
  return (
    <div className="text-center mt-2">
      <div className="mt-2">
        {[..."qwertyuiop"].map(key => <KeyboardButton
          key={key}
          used={allLettersUsed.has(key)}
          inWord={word.includes(key)}
          onClick={updateGuess(key)}
          value={key} />
        )}
      </div>
      <div className="">
        {[..."asdfghjkl"].map(key => <KeyboardButton
          key={key}
          used={allLettersUsed.has(key)}
          inWord={word.includes(key)}
          onClick={updateGuess(key)}
          value={key} />
        )}
      </div>
      <div className="">
        <button
          className={`border border-slate-800 bg-slate-100 rounded m-px xxs:mr-2 w-12 h-12 py-1`}
          onClick={enterGuess}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
        {[..."zxcvbnm"].map(key => <KeyboardButton
          key={key}
          used={allLettersUsed.has(key)}
          inWord={word.includes(key)}
          onClick={updateGuess(key)}
          value={key} />
        )}
        <button
          className={`border border-slate-800 bg-slate-100 rounded m-px xxs:ml-2 w-12 h-12 py-1`}
          onClick={updateGuess("\u200b", true)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
          </svg>
        </button>
      </div>
    </div>
  );
}
