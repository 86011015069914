/** @typedef {"✓" | "→" | "←" | "x"} mark */
/** @typedef {{ letter: string, marks: mark[] }[]} score */
/**
 * @typedef {
    {
      letter: string,
      marks: mark[],
      evaluation: boolean[]
    }[]
  } evaluatedScore
 */

/**
 * @param {string} guess
 * @param {score[]} scores
 * @returns {evaluatedScore[]}
 */
export function evaluateScores(guess, scores) {
  /** @type {evaluatedScore[]} */
  const evaluated = [];
  for (const score of scores) {
    evaluated.push([]);
    for (let i = 0; i < score.length; i++) {
      const { letter, marks } = score[i];
      /** @type {{ letter: string, marks: mark[], evaluation: boolean[] }} */
      const current = { letter, marks: [ ...marks ], evaluation: [] };
      evaluated[evaluated.length - 1].push(current);
      if (marks[0] === "x") {
        current.evaluation.push(!guess.includes(letter));
      }
      else {
        const actualLefts = Array.from(guess.substring(0, i))
          .filter(l => l === letter)
          .length;
        const actualRights = Array.from(guess.substring(i + 1))
          .filter(l => l === letter)
          .length;
        let lefts = 0;
        let rights = 0;
        for (let j = 0; j < marks.length; j++) {
          const mark = marks[j];
          if (mark === "✓") {
            current.evaluation.push(guess[i] === letter);
          }
          else if (mark === "←") {
            lefts += 1;
            current.evaluation.push(lefts <= actualLefts);
          }
          else if (mark === "→") {
            rights += 1;
            current.evaluation.push(rights <= actualRights);
          }
        }
      }
    }
  }
  return evaluated;
}

/**
 * @param {string} word
 * @param {string} guess
 * @returns {score}
 */
export function scoreGuess(word, guess) {
  /** @type {score} */
  const score = [];

  // For each letter in the guess
  for (let i = 0; i < guess.length; i++) {
    const letter = guess[i];
    score.push({ letter, marks: [] });

    // For each letter in the target word
    for (let j = 0; j < word.length; j++) {
      if (word[j] === letter) {
        if (i === j) {
          score[i].marks.push("✓");
        }
        else if (i < j) {
          score[i].marks.push("→");
        }
        else {
          score[i].marks.push("←");
        }
      }
    }

    if (score[i].marks.length === 0) {
      score[i].marks.push("x");
    }
  }

  return score;
}
