import classNames from "classnames";

export function KeyboardButton({ inWord, onClick, used, value }) {
  const className = classNames(
    "border-slate-800",
    "border",
    "h-12",
    "m-px",
    "py-1",
    "rounded",
    "uppercase",
    "w-6",
    "xxs:w-8",
    "md:w-10",
    {
      "bg-green-200": used && inWord,
      "bg-slate-500": used && !inWord,
      "bg-slate-100": !used,
    }
  );

  return (
    <button
      className={className}
      onClick={onClick}
    >
      {value}
    </button>
  );
}
