/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";

/**
 * @param {Date} epoch
 * @returns {number}
 */
function todayIndex(epoch) {
  // const now = window.location.hash
  //   ? new Date(window.location.hash.substring(1))
  //   : new Date();
  const now = new Date();
  return Math.floor(
    (now.getTime() - epoch.getTime())
    /
    86400000
  );
}

function getDaily(name, initial, today) {
  const obj = JSON.parse(window.localStorage.getItem(name) || "{}");
  if (String(today) in obj) {
    return obj[today];
  }
  return initial;
}

/**
 * @param {Date} epoch
 * @param {any} initial
 */
export function useCalculatedDaily(epoch, calculator) {
  // On load, today has the index of today
  const today = useRef(todayIndex(epoch));

  // On load, state has the calculated value for today
  const [ state, setState ] = useState(calculator(today.current));

  // Whenever today changes, update today and reset state to initial value
  useEffect(() => {
    if (todayIndex(epoch) !== today.current) {
      today.current = todayIndex(epoch);
      // React doesn't like it when you set state in a useEffect because it
      // could trigger an infinite loop... Sorry, React, doing it anyway!
      setState(calculator(today.current));
    }
  });

  // Create a setState wrapped which only sets state if it's still today
  const guardedSetState = (...args) => {
    if (todayIndex(epoch) === today.current) {
      setState(...args);
    }
  };

  return [ state, guardedSetState ];
}

/**
 * @param {Date} epoch
 * @param {string} name
 * @param {any} initial
 */
export function useStoredDaily(epoch, name, initial) {
  // On load, today has the index of today
  const today = useRef(todayIndex(epoch));

  // On load, state has the daily value for today, or the initial value if that
  // doesn't exist
  const [ state, setState ] = useState(getDaily(name, initial, today.current));

  // Whenever state changes, store value. It's fine to wipe out whatever might
  // have already existed in state because we have guaranteed that state can
  // only change when today is correct
  useEffect(() => {
    window.localStorage.setItem(name, JSON.stringify({
      [String(today.current)]: state
    }));
  }, [ name, state ]);

  // Whenever today changes, update today and reset state to initial value
  useEffect(() => {
    if (todayIndex(epoch) !== today.current) {
      today.current = todayIndex(epoch);
      // React doesn't like it when you set state in a useEffect because it
      // could trigger an infinite loop... Sorry, React, doing it anyway!
      setState(initial);
    }
  });

  // Create a setState wrapped which only sets state if it's still today
  const guardedSetState = (...args) => {
    if (todayIndex(epoch) === today.current) {
      setState(...args);
    }
  };

  return [ state, guardedSetState ];
}
