import * as ClipboardJS from "clipboard";
import { useEffect, useRef, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { yellow } from "tailwindcss/colors";
import { fontSize } from "tailwindcss/defaultTheme";

import day from "./day.json";
import dictionary from "./dictionary.json";
import par from "./par.json";
import words from "./words.json";

import { Keyboard } from "./components/Keyboard";
import { Grid } from "./components/Grid";
import * as game from "./game";
import { useCalculatedDaily, useStoredDaily } from "./hooks/useDaily";

const EPOCH = new Date("2022-06-20T00:00:00");

function todayIndex() {
  // const now = window.location.hash
  //   ? new Date(window.location.hash.substring(1))
  //   : new Date();
  const now = new Date();
  return Math.floor(
    (now.getTime() - EPOCH.getTime())
    /
    86400000
  );
}

function letterWord() {
  return words.at(day.par.at(todayIndex()));
}

function timeWord() {
  return words.at(day.time.at(todayIndex()));
}

function formatTimeDiffMillis(totalMillis) {
  return formatTimeDiff(Math.floor(totalMillis / 1000));
}

function formatTimeDiff(totalSeconds) {
  const elapsedSeconds = totalSeconds % 60;
  const remainingSeconds = totalSeconds - elapsedSeconds;
  const totalMinutes = remainingSeconds / 60;
  const elapsedMinutes = totalMinutes % 60;
  const remainingMinutes = totalMinutes - elapsedMinutes;
  const totalHours = remainingMinutes / 60;
  return [
    String(totalHours).padStart(2, "0"),
    String(elapsedMinutes).padStart(2, "0"),
    String(elapsedSeconds).padStart(2, "0")
  ].join(":");
}

function App() {
  const today = todayIndex();

  // Shared hooks
  const [ activeRound, setActiveRound ] = useState("letter");
  const [ activeScreen, setActiveScreen ] = useState("game");
  const [ caret, setCaret ] = useState(0);
  const [ guess, setGuess ] = useState("\u200b\u200b\u200b\u200b\u200b\u200b\u200b\u200b\u200b");

  // Letter round hooks
  const [ letterHistory, setLetterHistory ] = useStoredDaily(
    EPOCH,
    "dailyLetterHistory",
    []
  );
  const [ letterTarget ] = useCalculatedDaily(EPOCH, letterWord);

  // Time round hooks
  const [ timeHistory, setTimeHistory ] = useStoredDaily(
    EPOCH,
    "dailyTimeHistory",
    []
  );
  const [ timeStart, setTimeStart ] = useStoredDaily(
    EPOCH,
    "dailyTimeStart",
    null
  );
  const [ timeCurrent, setTimeCurrent ] = useState(new Date().getTime());
  const [ timeStop, setTimeStop ] = useStoredDaily(
    EPOCH,
    "dailyTimeStop",
    null
  );
  const [ timeTarget ] = useCalculatedDaily(EPOCH, timeWord);

  // Computed hooks
  const elapsed = (
    timeStart &&
    formatTimeDiffMillis((timeStop || timeCurrent) - timeStart)
  ) || "00:00:00";
  const [ statsData, setStatsData ] = useState(
    JSON.parse(window.localStorage.getItem("stats") || "{}")
  );

  // Specific round calculations
  const letterComplete = letterHistory.at(-1) === letterTarget;
  const letterScore = letterHistory.reduce((acc, cur) => acc + cur.length, 0);
  const letterParDiff = letterScore - par[letterTarget].p50;
  const timeComplete = timeHistory.at(-1) === timeTarget;

  const shareMessage = `Letterways #${
    today
  }\n${
    (letterComplete && `${letterParDiff}\n`) || ""
  }${
    (timeComplete && `${elapsed}\n`) || ""
  }https://letterways.com`;

  // UI hooks
  const div = useRef(null);
  const shareButton = useRef(null);
  useEffect(() => {
    if (div.current) {
      div.current.focus();
    }
    if (shareButton.current) {
      const clip = new ClipboardJS(shareButton.current);
      clip.on("success", () => {
        toast("Copied scores to clipboard");
      });
      return () => clip.destroy();
    }
  }, []);

  // Completion hooks
  useEffect(() => {
    if (letterComplete) {
      const stats = JSON.parse(window.localStorage.getItem("stats") || "{}");
      const todayStats = stats[String(today)] || {};
      todayStats.letter = letterParDiff;
      stats[String(today)] = todayStats;
      window.localStorage.setItem("stats", JSON.stringify(stats));
    }
  }, [ letterComplete, letterParDiff, today ]);
  useEffect(() => {
    // Do nothing if time round hasn't started
    if (!timeStart) return;
    // Start a ticking clock
    let interval;
    if (!timeStop) {
      interval = setInterval(() => {
        setTimeCurrent(new Date().getTime());
      }, 1000);
    }
    // Save stats and stop time if time round is complete
    if (timeComplete && !timeStop) {
      // Update stats
      const elapsedMilliseconds = new Date().getTime() - timeStart;
      const totalSeconds = Math.floor(elapsedMilliseconds / 1000);
      const stats = JSON.parse(window.localStorage.getItem("stats") || "{}");
      const todayStats = stats[String(today)] || {};
      todayStats.time = totalSeconds;
      stats[String(today)] = todayStats;
      window.localStorage.setItem("stats", JSON.stringify(stats));
      // Set stop time
      setTimeStop(new Date().getTime());
      // Stop looping
      clearInterval(interval);
    };
    return () => clearInterval(interval);
  }, [ setTimeStop, timeComplete, timeStart, timeStop, today ]);

  // Selected round calculations
  const activeComplete = activeRound === "letter" ? letterComplete : timeComplete;
  const activeHistory = activeRound === "letter" ? letterHistory : timeHistory;
  const setActiveHistory = activeRound === "letter" ? setLetterHistory : setTimeHistory;
  const activeTarget = activeRound === "letter" ? letterTarget : timeTarget;

  const allLettersUsed = new Set(activeHistory.flatMap(record => [ ...record ]));

  const gameScores = activeHistory
    .map(record => game.scoreGuess(activeTarget, record));

  const gameEvaluations = game.evaluateScores(
    guess.replace(/\u200b/g, " ").trimEnd(),
    gameScores
  );

  const impossibleGuess = gameEvaluations
    .some(score => score
      .some(({ evaluation }) => evaluation
        .some(allowed => !allowed)));

  // Selected round UI functions
  const enterGuess = () => {
    const trimmedGuess = guess.replace(/\u200b/g, " ").trimEnd();
    if (!dictionary.includes(trimmedGuess)) {
      toast(`${trimmedGuess.toUpperCase()} is not in the word list`);
      return;
    }
    if (activeRound === "time" && impossibleGuess) {
      toast(`${trimmedGuess.toUpperCase()} does not follow all current clues`);
      return;
    }
    if (activeHistory.length === 0 && activeRound === "time") {
      setTimeStart(new Date().getTime());
      setTimeCurrent(new Date().getTime());
    }
    setActiveHistory(h => [ ...h, trimmedGuess ]);
    setGuess("\u200b\u200b\u200b\u200b\u200b\u200b\u200b\u200b\u200b");
    setCaret(0);
  };

  // Global UI functions
  const caretLeft = () => setCaret(c => Math.max(0, c - 1));

  const caretRight = () => setCaret(c => Math.min(8, c + 1));

  const updateGuess = (key, reverse) => () => {
    setGuess(g => g.substring(0, caret) + key + g.substring(caret + 1));
    if (reverse) {
      caretLeft();
    }
    else {
      caretRight();
    }
  };

  const help = () => {
    setActiveScreen("help");
  };

  const stats = () => {
    setStatsData(JSON.parse(window.localStorage.getItem("stats") || "{}"));
    setActiveScreen("stats");
  };

  // Calculate displayable stats data
  const statsReport = {
    totalParDiff: 0,
    averageParDiff: 0,
    totalLetterComplete: 0,
    totalElapsed: 0,
    averageTimeElapsed: 0,
    totalTimeComplete: 0,
  };
  for (const key of Object.keys(statsData)) {
    const dailyStats = statsData[key];
    if (dailyStats.letter !== undefined) {
      statsReport.totalParDiff += dailyStats.letter;
      statsReport.totalLetterComplete += 1;
    }
    if (dailyStats.time !== undefined) {
      statsReport.totalElapsed += dailyStats.time;
      statsReport.totalTimeComplete += 1;
    }
  }
  statsReport.averageParDiff = Math.round(
    statsReport.totalParDiff / statsReport.totalLetterComplete
  );
  statsReport.averageTimeElapsed = Math.round(
    statsReport.totalElapsed / statsReport.totalTimeComplete
  );

  /** @param {KeyboardEvent} event */
  const keyDown = (event) => {
    if (event.key === "ArrowLeft") {
      caretLeft();
    }
    else if (event.key === "ArrowRight") {
      caretRight();
    }
    else if (event.key === "Backspace" || event.key === "Delete") {
      updateGuess("\u200b", true)();
    }
    else if (event.key === "Enter") {
      enterGuess();
    }
    else if (/^[a-z]$/i.test(event.key)) {
      updateGuess(event.key)();
    }
  };

  return (
    <div
      className="max-w-prose m-auto p-2 flex flex-col flex-nowrap h-full focus:outline-none focus:outline-0"
      onKeyDown={keyDown}
      ref={div}
      tabIndex={0}
    >
      <div className="flex-grow">
        <div className="flex flex-row flex-nowrap">
          <h1 className="font-bold text-xl xxs:text-2xl uppercase">Letterways #{today}</h1>
          <div className="grow flex flex-row-reverse flex-nowrap">
            <button
              className={`flex flex-row flex-nowrap font-bold uppercase border rounded border-slate-800 bg-slate-100 p-1 ml-1 ${letterComplete && timeComplete ? "bg-green-200" : ""}`}
              title="Share"
              data-clipboard-text={shareMessage}
              ref={shareButton}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
              <span className="ml-1 hidden xs:block">Share</span>
            </button>
            <button
              className={`flex flex-row flex-nowrap font-bold uppercase border rounded ${activeScreen === "help" ? "outline outline-sky-300 border-sky-600 bg-sky-100" : "border-slate-800 bg-slate-100"} p-1 ml-1`}
              title="Help"
              onClick={help}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="ml-1 hidden xs:block">Help</span>
            </button>
            <button
              className={`flex flex-row flex-nowrap font-bold uppercase border rounded ${activeScreen === "stats" ? "outline outline-sky-300 border-sky-600 bg-sky-100" : "border-slate-800 bg-slate-100"} p-1 ml-1`}
              title="Stats"
              onClick={stats}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              <span className="ml-1 hidden xs:block">Stats</span>
            </button>
          </div>
        </div>
        {Object.keys(statsData).length < 7 && letterHistory.length === 0 && timeHistory.length === 0 && (
          <p>
            Pick "letter round" or "time round" and guess a word to start. Or
            tap <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg> for help. Each day, you can play both rounds once.
          </p>
        )}
        <div className="flex flex-row flex-nowrap">
          <button
            className={`${letterComplete ? "bg-check" : ""} bg-right-top bg-no-repeat bg-auto flex-1 text-center uppercase border border-slate-800 rounded m-1 p-px ${(activeRound === "letter" && activeScreen === "game") ? "outline outline-sky-300 border-sky-600 bg-sky-100" : "bg-slate-100"}`}
            onClick={() => {
              setActiveRound("letter");
              setActiveScreen("game");
            }}
          >
            Letter round
            <br/>
            {letterParDiff} (Par {par[letterTarget].p50})
          </button>
          <button
            className={`${timeComplete ? "bg-check" : ""} bg-right-top bg-no-repeat bg-auto flex-1 text-center uppercase border border-slate-800 rounded m-1 p-px ${(activeRound === "time" && activeScreen === "game") ? "outline outline-sky-300 border-sky-600 bg-sky-100" : "bg-slate-100"}`}
            onClick={() => {
              setActiveRound("time");
              setActiveScreen("game");
            }}
          >
            Time round
            <br/>
            {elapsed}
          </button>
        </div>
        {activeScreen === "game" && <Grid
          history={activeHistory}
          word={activeTarget}
          gameEvaluations={gameEvaluations}
          complete={activeComplete}
          guess={guess}
          allLettersUsed={allLettersUsed}
          caret={caret}
          setCaret={setCaret}
        />}
        {activeScreen === "stats" && (
          <div>
            <h2 className="uppercase font-bold">Letter round stats</h2>
            <p>
              {statsReport.totalLetterComplete} rounds complete
            </p>
            <p>
              {statsReport.totalParDiff} total score
            </p>
            {statsReport.totalLetterComplete > 0 && (
              <p>
                {statsReport.averageParDiff} average score
              </p>
            )}
            <h2 className="uppercase font-bold">Time round stats</h2>
            <p>
              {statsReport.totalTimeComplete} rounds complete
            </p>
            <p>
              {formatTimeDiff(statsReport.totalElapsed)} total time
            </p>
            {statsReport.totalTimeComplete > 0 && (
              <p>
                {formatTimeDiff(statsReport.averageTimeElapsed)} average time
              </p>
            )}
          </div>
        )}
        {activeScreen === "help" && (
          <div>
            <p className="mb-3">
              Letterways is a word guessing game. There are two rounds each day:
              the letter round and the time round. In both rounds, the answer is
              a 5-9 letter English word.
            </p>
            <p className="mb-3">
              To start a round, tap the button for the round you want to play,
              type in a word of 5-9 letters, and click the circled check button
              on the keyboard. Then you will get guidance on what letters are in
              the word, what letters are not, and where letters in the word are
              relative to where you placed them in your guess.
            </p>
            <p className="mb-3">
              You will get a clue to the target word for each letter in the word
              you guess. If that letter does not appear in the target word, you
              will get an "X". If it appears in the target word in the same
              position as in your guess, you will get a "✓". If it appears in
              the target word but not in the same position as your guess, you
              will see a left or right arrow pointing in the direction of where
              the letter actually occurs in the target word. If there is more
              than one of the same letter in the target word, you will see
              multiple clues below the letter in your guess.
            </p>
            <p className="mb-3">
              In the letter round, try to guess the word using as few total
              letters as possible (golf-like scoring, low score is better). You
              are allowed to guess words that don't follow the clues you've
              gotten on previous guesses.
            </p>
            <p className="mb-3">
              In the time round, try to guess the word as quickly as possible.
              In this round, every guess you make must follow all previous
              clues. You are not allowed to guess a word that is impossible
              based on clues you've received so far.
            </p>
            <p className="mb-3">
              Tap <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg> after you've completed the rounds to share your results.
              Tap <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg> to see your stats.
            </p>
          </div>
        )}
      </div>
      {!activeComplete && activeScreen === "game" && (
        <Keyboard
          caretLeft={caretLeft}
          updateGuess={updateGuess}
          caretRight={caretRight}
          allLettersUsed={allLettersUsed}
          word={activeTarget}
          enterGuess={enterGuess}
        />
      )}
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            background: yellow["50"],
            fontSize: fontSize["xl"][0],
          }
        }}
      />
    </div>
  );
}

export default App;
